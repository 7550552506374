<template>
  <div class="row mx-0 justify-content-center" >
    <div class="col-11 col-md-9 col-lg-8 col-xl-7 col-xxl-7 p-0">
      <UserDetails />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    UserDetails: defineAsyncComponent(() => import('./views/userdetails/UserDetails.vue'))
  },
  name: 'Wizard 1'
}

</script>
